<template>
  <div class="page-layout">
    <div class="img-box">
      <img src="~@/assets/count/count-back.png" class="count-back" />
      <div class="content-box" v-if="dateNum > 0" @touchmove.prevent>
        <img src="~@/assets/count/logo-text.png" class="logo-text" />
        <img src="~@/assets/count/num.png" class="num" />
        <div class="count-time">
          <span>距离</span>
          <span class="day-num">{{ dateNum }}</span>
          <span
            >天 {{ format(hNum) }}:{{ format(mNum) }}:{{ format(sNum) }}</span
          >
        </div>
        <div class="up-time">正式上线：{{ dateTime() }}</div>
      </div>
      <div class="content-box" @touchmove.prevent v-else>
        <img src="~@/assets/count/logo-text.png" class="logo-text" />
        <img src="~@/assets/count/stopwatch.png" class="num" />
        <div class="gap-time">秒杀活动即将开始</div>
        <div class="stop-watch">
          <p class="time">{{ format(hNum) }}</p>
          <p class="colon">:</p>
          <p class="time">{{ format(mNum) }}</p>
          <p class="colon">:</p>
          <p class="time">{{ format(sNum) }}</p>
        </div>
        <div class="up-time">正式上线：{{ dateTime() }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStartTime } from "@/api/common";
import Cookies from "js-cookie";

export default {
  name: "",
  data() {
    return {
      startTime: 0,
      autoTime: null,
      dateNum: 0,
      hNum: 0,
      mNum: 0,
      sNum: 0,
    };
  },
  mounted() {
    this.isIphone();
    // this.getStartTime();
  },
  methods: {
    async getStartTime() {
      let ret = await getStartTime();
      if (ret.code * 1 == 1) {
        this.startTime = ret.data.start_time;
        this.setDate();
        this.autoTime = setInterval(() => {
          this.setDate();
        }, 1000);
      }
    },
    setDate() {
      let toTime = parseInt(new Date().getTime() / 1000);
      if (toTime >= this.startTime) {
        //跳转首页
        clearInterval(this.autoTime);
        this.$store.commit("setStstues", 1);
        // localStorage.setItem("STATUS", 1);
        this.$router.push({ name: "home" });
      } else {
        this.dateNum = parseInt((this.startTime - toTime) / 86400);
        let hTime = (this.startTime - toTime) % 86400;
        this.hNum = parseInt(hTime / 3600);
        let mTime = hTime % 3600;
        this.mNum = parseInt(mTime / 60);
        this.sNum = mTime % 60;
      }
    },
    dateTime: function () {
      //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
      let date = new Date(this.startTime * 1000);
      let y = 1900 + date.getYear();
      let d_m = "0" + (date.getMonth() * 1 + 1);
      let d = "0" + date.getDate();
      let h = "0" + date.getHours();
      let m = "0" + date.getMinutes();
      let s = "0" + date.getSeconds();
      return (
        y +
        "年" +
        d_m.substring(d_m.length - 2, d_m.length) +
        "月" +
        d.substring(d.length - 2, d.length) +
        "日 " +
        h.substring(h.length - 2, h.length) +
        ":" +
        m.substring(m.length - 2, m.length) +
        ":" +
        s.substring(s.length - 2, s.length)
      );
    },
    format: function (num) {
      let s = "0" + num;
      return s.substring(s.length - 2, s.length);
    },
    isIphone() {
      let pageLayout = document.querySelector(".page-layout");
      // let countTime = document.querySelector(".count-time");
      let upTime = document.querySelector(".up-time");
      let logoText = document.querySelector(".logo-text");
      let num = document.querySelector(".num");
      let stopWatch = document.querySelector(".stop-watch");
      let isIphoneX =
        window.devicePixelRatio &&
        window.devicePixelRatio === 3 &&
        window.screen.width === 375 &&
        testUA("iPhone");
      if (isIphoneX) {
        check();
        window.onscroll = check();
      }

      function check() {
        // 处理lib-flexible放大viewport的情况
        let scale = window.innerWidth / window.screen.width;
        // 部分浏览器在滚动页面时会显示/隐藏工具栏，影响视口高度。在有底部工具栏的情况下，不做iPhoneX的fix。100为经验值
        if (window.screen.height - window.innerHeight / scale < 100) {
          // stopWatch.classList.add("stop-watch-top");
          pageLayout.classList.add("fix-iphonex-bottom");
          // countTime.classList.add("count-time-bottom");
          upTime.classList.add("up-time-bottom");
          logoText.classList.add("logo-text-top");
          // num.classList.add("num-top");
        } else {
          // stopWatch.classList.remove("stop-watch-top");
          pageLayout.classList.remove("fix-iphonex-bottom");
          // countTime.classList.remove("count-time-bottom");
          upTime.classList.remove("up-time-bottom");
          logoText.classList.remove("logo-text-top");
          // num.classList.remove("num-top");
        }
      }

      function testUA(str) {
        return navigator.userAgent.indexOf(str) > -1;
      }
    } /*  */,
    adaptScreen() {
      let visibleHeight = document.body.clientHeight;
      let countTime = document.querySelector(".count-time");
      let upTime = document.querySelector(".up-time");
      if (visibleHeight >= 812) {
        countTime.classList.add("count-time-bottom");
        upTime.classList.add("up-time-bottom");
      } else {
        countTime.classList.remove("count-time-bottom");
        upTime.classList.remove("up-time-bottom");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-layout {
  position: relative;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;

  .count-back {
    display: block;
    width: 100%;
    height: auto;
  }
}

.content-box {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 654px;

  .logo-text {
    position: absolute;
    left: 50%;
    top: 27px;
    transform: translateX(-50%);
    width: 258px;
    height: 122px;
  }

  .num {
    position: absolute;
    top: -44px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: auto;
  }

  .count-time {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 80px;
    transform: translateX(-50%);
    left: 50%;
    width: 204px;
    height: 44px;
    background: linear-gradient(0deg, #eb4631, #ed663b);
    border: 2px solid #ffe19f;
    box-shadow: 0px 7px 9px 0px rgba(231, 111, 63, 0.89),
      0px 2px 9px 0px #ffe2c0;
    border-radius: 25px;
    text-align: center;
    color: #fff;
    font-size: 21px;

    .day-num {
      font-size: 33px;
    }
  }

  .up-time {
    position: absolute;
    bottom: 27px;
    left: 50%;
    transform: translate(-50%);
    width: 379px;
    height: 55px;
    background: rgba(219, 62, 54, 0.2);
    border: 1px solid rgba(251, 239, 255, 0.2);
    box-sizing: border-box;
    line-height: 55px;
    font-size: 18px;
    color: #fff;
    text-align: center;
  }
}

.content-box {
  .gap-time {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translateX(-50%);
    width: 243px;
    height: 40px;
    background: linear-gradient(180deg, #e36e37, #e16032, #dd492a);
    border: 2px solid #fffdf9;
    border-radius: 20px;
    color: #fff;
    font-size: 21px;
    text-align: center;
    line-height: 40px;
  }

  .stop-watch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 283px;
    left: 50%;
    transform: translateX(-50%);
    width: 270px;
    font-size: 45px;
    color: #fff;
    padding: 0 6px 0 4px;
    box-sizing: border-box;

    p {
      margin: 0;
    }

    .time {
      width: 70px;
      height: 130px;
      text-align: center;
      line-height: 130px;
      color: #da1f00;
      background: linear-gradient(
        180deg,
        #ea4e31,
        #ffffff,
        #f6fbf0,
        #ffffff,
        #e94c2f
      );
      box-shadow: 0px 2px 4px 0px #ec241a;
      border-radius: 6px;
    }

    .colon {
      padding-bottom: 6px;
    }
  }
}

.fix-iphonex-bottom {
  padding-bottom: 34px;
}

.count-time-bottom {
  bottom: 14% !important;
}

.up-time-bottom {
  bottom: -1% !important;
}

.logo-text-top {
  top: -5% !important;
}

// .num-top {
//   top: -58px !important;
// }
// .stop-watch-top {
//   top: 243px !important;
// }
</style>
